import styled from '@emotion/styled';
import { NextPage } from 'next';
import { APP_IMG } from 'src/constants/index';


import _ from 'lodash';
import dynamic from 'next/dynamic';
import { SHOW_COMMEMORATE } from 'src/constants';
import { convertDatasets } from 'src/utils/datasets';
import useResponsive from 'src/utils/devices';
const SectionByteArk = dynamic(import('src/components/sections/section-byteark'));

type Props = { data: any };
const SectionSpecialLive: NextPage<Props> = ({ data }) => {
  const responsive = useResponsive();
  const backgroundDT = convertDatasets(data, 'background-desktop', true);
  const backgroundMB = convertDatasets(data, 'background-mobile', true);
  const logo = convertDatasets(data, 'logo', true);


  return (
    <>
      {!_.isEmpty(data) && <SectionWrapper id='section-3' style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }}>
        <img src={responsive.isMobile ? backgroundMB : backgroundDT} alt='live' title='live' width='100%' height='100%' />

        <div className='container'>
          <SectionByteArk logo={<img className='logo-live' src={logo} alt='logo-live' title='live' width='100%' height='100%' />} />

        </div>
      </SectionWrapper>}

    </>
  );
};

const SectionWrapper = styled.section`
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;
  margin: 30px 0;


  height: 100%;
  max-width: 1980px;
  margin: auto;
  #vjs_video_3 {

  padding-top: 56.25%;
    max-width: 100%;
    margin: auto;
    top: 0px;
}
  .logo-live {
    position: absolute;
    bottom: -45px;
    right: 65%;
    width: 150px;
    height: 40px;
    @media (max-width:1024px) {
      bottom: -35px;
      width: 100px;
      height: 30px;
    }
    @media (max-width: 768px) {
      bottom: -28px;
      width: 90px;
      height: 25px;
    }
  }

  #byteark-player-video-player, #video-player {
    position: absolute;
    top: 15rem;
    left: auto;
    right: 6rem;
    width: 1050px;
    max-width: 100%;
    padding: 0;
    margin: 0;
    z-index: 1;
    border: 10px solid #060b2c;
    @media (max-width:1700px) {
      max-width:70%
    }
    @media (max-width:1440px) {
      top:11rem; 
      right: 4rem;

    }
    @media (max-width:1439px) {
      top: 8rem; 
      right: 4rem;

    }
    @media (max-width:1024px) {
      top: 8rem; 
      right: 3rem;

    }
    @media (max-width: 768px) {
      border: 5px solid #060b2c;

      top: 6rem; 
      right: 2rem;
    }
    @media (max-width: 767px) {
      max-width: 90%;
      top: 22rem;
      right: auto;
      left: auto;

    }
    @media (max-width: 690px) {
      top: 28rem; 

    }
    @media (max-width: 475px) {
      top: 14rem; 
      left: 20px;

    }
    @media (max-width: 320px) {
      /* max-width: 90%; */
      top: 12rem; 

      /* right: auto; */

    }
  }
  .container-live {
    display: grid;
    grid-template-columns: 1fr 3fr;
    max-width: 1400px !important;
    overflow: hidden;
    position: absolute;
    top: 2rem;
    left: 0;
    padding-top: 5rem;
    @media (max-width: 768px) {
        top: 20%;
        left: 50%;
        transform: translate(-50%, 0%);
        grid-template-columns: 1fr;
    }    .section-live-text {
      width: 30%;
      text-align: center;
      margin: auto;
      .logo {
        width: 100%;
        max-width: 150px;
        filter: drop-shadow(2px 4px 1px black);
      }

      span {
        padding: 5px 20px;
        background: #1e3256;
        color: #fff;
        font-size: 18px;
        font-weight: 300;
        margin: 30px auto 0 auto;
        display: block;
        width: max-content;
      }
      
      p {
        color: #fff;
        font-size: 36px;
        font-weight: 300;
        @media (max-width: 1024px) {
          font-size: 30px;
        }
        &:nth-child(2) {
          font-size: 44px;
          font-weight: 700;
          @media (max-width: 1024px) {
            font-size: 36px;

            font-weight: 500;
          }
        }
        &:not(:nth-child(2),:nth-child(1)) {
          font-size: 24px;
          font-weight: 300;
          font-style: italic;
          @media (max-width: 1024px) {
            font-size: 20px;
          }
        }
        &:last-child {
          font-size: 1110px;
          margin-bottom: 30px;
        }
      }
    }
  }
  .block-1{
    display: block;
    position: relative;
    background-size: cover;
    width: 100%;
    padding-top: 57.25%;
    background: #000;
    border-radius: 8px;
    /* background: url(${APP_IMG}/images/border-background.png); */
    background-repeat: no-repeat;
    margin: auto;
    @media (max-width: 1024px) {
        padding-top: 58.25%;
    }


    .live-button {
      display: flex;
      align-items: center;
      text-align: center;
      background: #eb212d;
      border-radius: 8px;
      position: absolute;
      top: -50px;
      right: 0;
      padding: 5px 10px;
      font-size: 20px;
      text-transform: uppercase;
      color: #fff;
      svg {
        margin-right: 15px;
      }
      svg path {
        fill: #fff;
        /* Copy this @keyframes block to your CSS*/
        @keyframes signalanimate {
          0% {
            opacity: 0;
          }
          50% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
        &:nth-of-type(1) {
          -webkit-animation: signalanimate 2s infinite;
          animation: signalanimate 2s infinite;
        }
        &:nth-of-type(2) {
          -webkit-animation: signalanimate 2s infinite;
          animation: signalanimate 2s infinite;
          -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
        }
        &:nth-of-type(3) {
          -webkit-animation: signalanimate 2s infinite;
          animation: signalanimate 2s infinite;
          -webkit-animation-delay: 1s;
          animation-delay: 1s;
        }
      }
    }
    .video-live {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      padding: 30px 40px 60px 30px;
      @media (max-width: 1024px) {
        padding: 0;
      }
    }
  }
`;

export default SectionSpecialLive;
