import _ from 'lodash';
// import { useRouter } from 'next/router';
import { ReactElement, useEffect } from 'react';
import { CHECK_ADS } from 'src/constants';
import { NextPage } from 'next';
import { IAds } from 'src/interface/service';
import { TKeyAdsPage, TKeyAdsPosition } from 'src/interface/ads';
import { useMediaQuery } from 'react-responsive';
import styled from '@emotion/styled';
import { SHOW_COMMEMORATE } from 'src/constants';
// ? TODO:  <DynamicAds position={KEY_ADS_POSITION.BILLBOARD} page={KEY_ADS_PAGE.CONTENT} />

/* -------------------------------- KEY_TYPE -------------------------------- */

export const KEY_ADS_POSITION: TKeyAdsPosition = {
  BILLBOARD: 'billboard',
  STICKY_1: 'sticky-1',
  STICKY_2: 'sticky-2',
  STICKY_3: 'sticky-3',
  INARTICLE_1: 'inarticle-1',
  INARTICLE_2: 'inarticle-2',
  INARTICLE_3: 'inarticle-3',
  POSTARTICLE: 'postarticle',
  AD1X1: 'ad1x1',
  LIGHTBOX: 'light-box',
  ELECTION_1: 'election-1',
  ELECTION_2: 'election-2',
  ELECTION_3: 'election-3',
  ELECTION_4: 'election-4',
  ELECTION_5: 'election-5',
  ELECTION_6: 'election-6',
  ELECTION_7: 'election-7'
};

export const KEY_ADS_PAGE: TKeyAdsPage = {
  HOMEPAGE: 'homepage',
  CATEGORY: 'category',
  CONTENT: 'content',
  TAG: 'tag',
  SEARCH: 'search'
};

export const Ads = ({ adsBox = false, slotId = '', slotSize = [], slotDefine = '', className = '', position, handler = () => { } }: IAds): ReactElement | null => {

  if (_.isEmpty(slotId) || _.isEmpty(slotDefine) || _.isEmpty(slotSize)) return null;
  useEffect(() => {
    window.googletag?.cmd.push(() => {
      window.googletag.defineSlot(slotDefine, slotSize, slotId).addService(window.googletag.pubads()).setCollapseEmptyDiv(true, true);
      window.googletag.pubads().enableSingleRequest();
      window.googletag.enableServices();

      window.googletag.cmd.push(function () {
        window.googletag.display(slotId);
      });
    });
  }, []);
  switch (position) {
    case 'light-box':
      return (
        <div>
          <AdsLightBox className={`light-box-ads ${!adsBox ? 'close' : 'open'}`}>
            <div className={'light-box'} onClick={handler}>
              <AdsWrapper className={className}>
                <div id={slotId} />
              </AdsWrapper>
            </div>
          </AdsLightBox>
        </div>
      );
    default:
      return (
        <AdsWrapper className={className}>
          {CHECK_ADS === 'true' && className}
          <div id={slotId} style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }} />
        </AdsWrapper>
      );
  }
};
const AdsLightBox = styled.div`
  z-index: 2147483647;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4142429c;

  &.close {
    display: none;
  }
  .light-box {
    position: relative;
    &::before {
      content: 'X';
      cursor: pointer;
      position: absolute;
      top: -24px;
      right: 0;
      color: rgba(0, 0, 0, 0.788);
      background-color: #fafafab7;
      border-top-right-radius: 3px;
      border-top-left-radius: 3px;
      padding: 2px 7px;
    }
  }
`;
const AdsWrapper = styled.div`
  &.ads-billboard {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    min-height: 300px;
    iframe {
      margin: auto;
    }
  }
  &.ads-ad1x1 {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  &.ads-sticky-1,
  &.ads-sticky-2,
  &.ads-sticky-3 {
    display: flex;
    justify-content: center;
    overflow: hidden;
    margin: 15px 0;
    min-height: 300px;
  }
  &.ads-postarticle {
    position: sticky;
    bottom: 0;
    z-index: 50;
    display: flex;
    justify-content: center;
    overflow: hidden;
    min-height: 90px;
  }

  &.ads-inarticle-1,
  &.ads-inarticle-2,
  &.ads-inarticle-3 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    margin: 30px auto;
    overflow: hidden;
    min-height: 90px;
    img,
    iframe {
      max-width: 100%;
      display: block;
      margin: auto;
    }
  }
  
  &.ads-election-1,
  &.ads-election-2,
  &.ads-election-3,
  &.ads-election-4 {
    margin: 15px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

type Props = {
  adsBox?: boolean;
  position?: TKeyAdsPosition[keyof TKeyAdsPosition];
  data?: IAds[];
  handler?: any;
};
const DynamicAds: NextPage<Props> = ({ adsBox = false, position = '', data = [], handler = () => { } }) => {
  const isMb = useMediaQuery({ query: '(max-width: 1023px)' });
  const isDt = useMediaQuery({ query: '(min-width: 1024px)' });
  const _adsArray = !_.isEmpty(data) ? data : [];

  const _adsSlot: any = _adsArray.find(_data => _data?.position === position);
  if (position === KEY_ADS_POSITION.POSTARTICLE) {
    // console.log(position, `ads-${position}`);
    return (
      <SlotWrapper className='ads-sticky-overflow'>
        <div className='mobile'>
          {!isDt && (
            <Ads adsBox={adsBox} className={`ads-${position}`} slotDefine={_adsSlot?.slotDefine} slotId={_adsSlot?.slotId} slotSize={_adsSlot?.sizeMobile} position={position} handler={handler} />
          )}
        </div>
        <div className='desktop'>
          {!isMb && (
            <Ads adsBox={adsBox} className={`ads-${position}`} slotDefine={_adsSlot?.slotDefine} slotId={_adsSlot?.slotId} slotSize={_adsSlot?.sizeDesktop} position={position} handler={handler} />
          )}
        </div>
      </SlotWrapper>
    );
  }
  if (!_.isEmpty(_adsSlot)) {
    return (
      <SlotWrapper>
        <div className='mobile'>
          {!isDt && (
            <Ads adsBox={adsBox} className={`ads-${position}`} slotDefine={_adsSlot?.slotDefine} slotId={_adsSlot?.slotId} slotSize={_adsSlot?.sizeMobile} position={position} handler={handler} />
          )}
        </div>
        <div className='desktop'>
          {!isMb && (
            <Ads adsBox={adsBox} className={`ads-${position}`} slotDefine={_adsSlot?.slotDefine} slotId={_adsSlot?.slotId} slotSize={_adsSlot?.sizeDesktop} position={position} handler={handler} />
          )}
        </div>
      </SlotWrapper>
    );
  }
  return null;
};
export default DynamicAds;

const SlotWrapper = styled.div`
  .mobile {
    display: none;
    @media (max-width: 1024px) {
      display: block;
    }
  }
  .desktop {
    display: block;
    @media (max-width: 1025px) {
      display: none;
    }
  }
  &.ads-sticky-overflow {
    width: 100%;
    text-align: center;
    position: sticky;
    bottom: 0;
    z-index: 5;
    margin: 15px auto;
  }
`;
